<template>
	<div class="out" ref="out"  >
		
		<div class="Top">
			<div class="header">
				<div class=""></div>

				<div class="right-icon">
					<img src="../assets/img/logo-icon.png" alt="" />
				</div>
			</div>
			<div class="bigTitle">
				<img src="../assets/img/bigtitle-new.png" alt="" srcset="" />
			</div>
			<div class="smallTitle">
				<img src="../assets/img/smalltitle2-new.png" alt="" />
			</div>
		</div>
		
		<div class="list">
			<div class="good" ref="imgBox" v-for="(item, index) in showList" :key="index" @click="goDetail(item.id)">
				<div class="good-tp">
					<img v-lazy="(item.pictures[0] + '?width=165').replace(/((^[A-Za-z]{4,5})+\:)/, protocol)" :style="`height:${(item._height / 100)}rem`" alt="" />
					<div class="icon" v-show="item.is_rare">
					<img src="../assets/img/logo-icon-right.png" alt="" />
				</div>
				</div>
				<div class="good_text">{{ item[Rname] }}</div>
			</div>
			<div style="width:10px;height:0.01rem;position: absolute;" ref="bottom" ></div>
		</div>
		<div class="bottom" v-show="is_next && loading1">
			<van-loading size="24" />
		</div>
		<changelang></changelang>
	</div>
</template>

<script>
import changelang from "../components/changelang.vue"
	export default {
		components: {
		changelang
		},
		data() {
			return {
				page: 1,
				list: [],
				imgWidth: 165,
				showList: [],
				beginIndex: 0,
				loadedCount: 0,
				colNum: 2,
				loading: null,
				is_next: false,
				canRun: true,
				loading1: false,
				timer: null,
				protocol:'http',
				
			};
		},
		computed:{
			Rname(){
				if(this.$i18n.locale == 'en'){
					return 'name_intl'
				}else{
					return 'name'
				}
			}
		},
		watch:{
			'$i18n.locale'(newVal){
				this.$router.go(0)
			}
		},
		mounted() {
			
			
			this.protocol = location.protocol
			this.axios.get("/goods/rare?page=" + this.page).then((res) => {
				for(let i = 0; i < res.data.data.data.length;i++){
					if(!res.data.data.data[i].name_intl){
						res.data.data.data[i].name_intl = res.data.data.data[i].name
					}
				}
				this.list = res.data.data.data;
				this.is_next = !!res.data.data.next_page_url
				this.preLoad()
			});
			this.$refs.out.addEventListener('scroll', this.throttle(this.scrollFn, 500))
		},
		methods: {
			loadDoodState(){
				setTimeout(()=>{
					this.isLoading = true
				},600)
			},
			goDetail(id) {
				this.$router.push(`/detail/${id}?status=1`);
			},
			preLoad() {
				this.loading = weui.loading(this.$t('main.loading'))
				this.list.forEach((item, index) => {

					// 无图则把高度设置为0
					if (!item.pictures) {
						// 图片的高度
						this.list[index]._height = "0";
						++this.loadedCount;
						// 当前图片都与处理完，则加载图片
						if (this.list.length === this.loadedCount) {
							this.preloaded();
						}
					} else {
						let img = new Image();

						img.onload = img.onerror = (e) => {

							// 若加载失败则设置图片高度与宽度一致，加载成功则动态计算图片高度
							this.list[index]._height =
								e.type === "load" ?
								Math.round(this.imgWidth * (img.height / img.width)) :
								this.imgWidth;
							if (e.type === "error") {
								this.list[index]._error = true;
							}
							++this.loadedCount;
							// 当前图片都与处理完，则加载图片
							if (this.list.length === this.loadedCount) {
								this.preloaded();
							}
						};
						img.src = (item.pictures[0] + '?width=165').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol);
					}
				});
			},
			preloaded() {
				this.showList = this.showList.concat(this.list);
				this.$nextTick(() => {
					this.waterfall();
				});
			},
			waterfall() {
				this.loading.hide()
				this.imgBoxEls = this.$refs["imgBox"];
				if (!this.imgBoxEls) return;
				let top, left, height;
				if (this.beginIndex === 0) this.colsHeightArr = [];
				for (let i = this.beginIndex; i < this.imgBoxEls.length; ++i) {
					if (!this.imgBoxEls[i]) return;
					// 当前图片的高度

					height = this.imgBoxEls[i].offsetHeight + 15

					// 第一行，则直接按顺序排列
					if (i < this.colNum) {
						this.colsHeightArr.push(height);
						top = 0;
						// colWidth 为列宽，等于图片宽度加 div 左右的padding，colWidth = imgWdith + 2 * padding
						left = i * 1.8 + 0.15;
					} else {
						// 找到当前最低的高度和其索引
						let minHeight = Math.min.apply(null, this.colsHeightArr);
						let minIdx = this.colsHeightArr.indexOf(minHeight);
						// 当前图片的 top，即当前图片应所在的高度
						top = minHeight;
						// 当前图片的 left，即当前图片应该排到目前高度最低那一列下面
						left = minIdx * 1.8 + 0.15;
						// 更新第 minIdx 列的高度
						this.colsHeightArr[minIdx] += height;
					}
					// 设置 img-box 位置
					this.imgBoxEls[i].style.top = (top + 15) + "px";
					this.imgBoxEls[i].style.left = left + "rem";
					
					// 当前图片在窗口内，则加载，这是用于后面的图片懒加载。viewHeight 为窗口高度
					if (top < this.viewHeight) {
						let imgEl = this.imgBoxEls[i].children[0];
						imgEl.src = imgEl.getAttribute("data-src");
						imgEl.style.opacity = 1;
						imgEl.style.transform = "scale(1)";
					}
				}
				let maxHeight = Math.max.apply(null, this.colsHeightArr);
				this.$refs.bottom.style.top = (maxHeight+15)+"px"
			},

			throttle(fn, time) {
				let canRun = true;
				return function() {

					if (!canRun)
						return;
					canRun = false;
					setTimeout(() => {
						fn.apply(this);
						canRun = true;
					}, time)
				}
			},

			scrollFn(e) {
				
				if (this.$refs.out.scrollHeight - this.$refs.out.scrollTop - this.$refs.out.clientHeight <= 20) {
					if (this.is_next) {
						this.page++
						this.loading1 = true
						this.axios.get("/goods/rare?page=" + this.page).then((res) => {
							
							this.loadedCount = 0
							for(let i = 0; i < res.data.data.data.length;i++){
								if(!res.data.data.data[i].name_intl){
									res.data.data.data[i].name_intl = res.data.data.data[i].name
								}
							}
							this.list = res.data.data.data;
							this.is_next = !!res.data.data.next_page_url
							this.loading1 = false
							this.preLoad()
						});

					}
				}

			},



		},
	};
</script>

<style scoped>
	.out {
		min-height: 100vh;
		box-sizing: border-box;
		background: url(../assets/img/index_bg.png) no-repeat top center #fafafa;
			background-size: cover;
			    background-attachment: fixed;
		overflow: auto;
		/* filter:grayscale(100%); */
	}

	.Top {
		padding: 0.3rem 0.15rem;
		padding-bottom: 0.2rem;
		/* background: url(../assets/img/list_bg.jpg) no-repeat center; */
		box-sizing: border-box;
		background-size: 100%;
		background: url(../assets/img/my-zb-bg.png) no-repeat center -0.85rem;
		background-size: 100%;
	}

	.header {
		display: flex;
		justify-content: center;
		/* line-height: 0; */
	}

	.Title {
		font-size: 0.15rem;
		position: relative;
		margin-top: -0.03rem;
	}

	.cc-wc {
		color: #eec194;
		font-size: 0.15rem;
	}

	.English {
		color: #754c4f;
		position: absolute;
		top: 0.05rem;
		left: 0.8rem;
	}

	.Chinese {
		color: #ffffff;
		left: 0;
		position: relative;
		text-align: left;
	}

	.right-icon {
		width: 1.11rem;
		/* height: 0.39rem; */
	}

	.right-icon img {
		display: block;
		width: 100%;
	}

	.weui-navbar__item {
		background-color: #fff;
	}

	.weui-navbar__item.weui-bar__item_on {
		background-color: #fff;
	}

	.weui-navbar__item {
		display: flex;
		justify-content: center;
	}

	.weui-navbar__item>div {
		width: max-content;
		box-sizing: border-box;
		padding: 0 0.02rem;
		color: #9e9e9e;
		font-size: 0.14rem;
	}

	.weui-navbar__item.weui-bar__item_on>div {
		box-shadow: 0 3px 0 0 #3662ec;
		color: #3662ec;
	}

	.weui_tab_bd_item {
		display: none;
	}

	.weui_tab_bd_item_active {
		display: block;
	}

	.weui-navbar__item:after {
		content: none;
	}

	.weui-navbar:after {
		content: none;
	}
	.list-bg-fiexd{
		    position: fixed;
		    width: 100%;
		    height: 100%;
			
	}
	.list {

		width: 100vw;
		position: relative;
		box-sizing: border-box;
		padding-top: 0;
		background: #f4f4f4;
		/*    min-height: calc(100vh + -1.9089rem);
		background: url(../assets/img/index_bg.png) no-repeat top center;
		background-size: cover;
		    background-attachment: fixed; */
	}

	.good {
		width: 1.65rem;
		/* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3); */
		border-radius: 0.08rem;
		position: absolute;
		margin-bottom: 0.15rem;
		overflow: hidden;
		background: #fff;
	}
	.good .good-tp{
		position: relative;
	}

	.good_text {
		font-size: 0.15rem;
		color: #000;
		font-weight: 500;
		box-sizing: border-box;
		overflow: hidden;
		width: 1.65rem;
		padding: 0.0375rem 0.1rem;
		text-overflow: ellipsis;
		text-align: left;
		background: #fff;
		text-overflow: ellipsis;

		-webkit-box-orient: vertical;

		display: -webkit-box;

		-webkit-line-clamp: 2;

		-webkit-box-flex: 2;
	}

	.good img {
		width: 1.65rem;
		object-fit: contain;
		display: block;
	}

	.icon {
		position: absolute;
		bottom: 0.06rem;
		right: 0.06rem;
		width: 0.24rem;
	}

	.icon img {
		width: 100%;
		height: 100%;
	}

	.logo {
		/* width: 1rem;
		height: 0.39rem; */
	}

	.logo img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.bigTitle {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.bigTitle img {
		width: 3.595rem;
		object-fit: contain;
	}

	.smallTitle {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.smallTitle img {
		width: 1.475rem;
		object-fit: contain;
	}

	.bottom {
		width: 100%;
		position: absolute;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
